import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { images } from "../Utility/Images";
import menuimg from "../../assets/images/top_megamenuimg.webp";
import solutionsimg from "../../assets/images/solutionsimg.webp";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";

import { AiOutlineDownload } from "react-icons/ai";
import { Link, NavLink } from "react-router-dom";
import { FiMail } from "react-icons/fi";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { IoMdCall } from "react-icons/io";
import Accordion from "react-bootstrap/Accordion";
import Modal from "react-bootstrap/Modal";
import { BrochureDownloadApi } from "../../services/front.service";
import { toast } from "react-hot-toast";
import crossicn from "../../assets/images/crossicon/crossicn.png";
import marketplace from "../../assets/images/benefits/marketplace.webp";
import MLandAI from "../../assets/images/header/ml.webp";
import ARandVR from "../../assets/images/header/ar.webp";
import brochure from '../../assets/images/brochure/brochureimg.webp'
function Header() {
  // const [navbartollge, setNavbartollge] = useState(false);
  const [navbartollge, setNavbartollge] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // const [showButton, setShowButton] = useState(true);
  // const [hidemegamenu, setHidemegamenu] = useState(true);
  const pickerRef = useRef(null);
  const pickerRef1 = useRef(null);
  const toggleOffCanvas = () => {
    setNavbartollge((navbartollge) => !navbartollge);
  };

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  useLayoutEffect(() => {
    window.addEventListener("resize", function updateSize() {
      setWindowWidth(window.innerWidth);
    });
    setWindowWidth(window.innerWidth);
    return () =>
      window.removeEventListener("resize", function updateSize() {
        setWindowWidth(window.innerWidth);
      });
  }, [window]);

  useEffect(() => {
    if (
      pickerRef.current !== null &&
      pickerRef1.current !== null &&
      pickerRef.current.input &&
      pickerRef1.current.input
    ) {
      if (windowWidth > 992) {
        pickerRef.current.input.readOnly = false;
      } else {
        pickerRef.current.input.readOnly = true;
      }
      if (windowWidth > 992) {
        pickerRef1.current.input.readOnly = false;
      } else {
        pickerRef1.current.input.readOnly = false;
      }
    }
    console.log("asd");
  }, [windowWidth, pickerRef, pickerRef1]);
  // const [message, setMessage] = useState("Javascript is so cool");

  // function handleButtonClick(url) {}

  const [isShown, setIsShown] = useState(false);
  useEffect(() => {
    console.log(isShown, "asdfasdf");
  }, [isShown]);

  const [isShown1, setIsShown1] = useState(false);

  const HandleSubmitBrochureEnquiry = async () => {
    try {
      let regex = new RegExp("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}$");
      let Phoneregex = new RegExp("^[7-9][0-9]{9}$");

      if (name === "") {
        toast.error("Name is mandatory !!!");
        return;
      }
      if (email === "") {
        toast.error("Email is mandatory !!!");
        return;
      }
      if (regex.test(email) === false) {
        toast.error("Invalid Email provided !!!");
        return;
      }
      if (phone === "") {
        toast.error("Phone number is mandatory !!!");
        return;
      }
      if (Phoneregex.test(phone) === false) {
        toast.error("Invalid Phone number provided !!!");
        return;
      }

      let obj = {
        name,
        email,
        phone,
      };
      console.log(obj);
      let { data: res } = await BrochureDownloadApi(obj);
      if (res.message) {
        setName("");
        setEmail("");
        setPhone("");
        handleCloseModal();
        toast.success(res.message);
        window.open(
          "https://ebslon.com/api/Ebslon.pdf",
          "_blank" // <- This is what makes it open in a new window.
        );
        // alert(res.message);
      }
    } catch (err) {
      alert(err);
    }
  };

  let megamenu = () => {
    return (
      <ul>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/educational-app-development">
            <div className="iconimg">
              <img src={images.solutionsimg12} className="img-fluid" alt=" " />
            </div>
            <span> ED Tech</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/custom-crm-development-services">
            <div className="iconimg">
              {" "}
              <img src={images.solutionsimg1} className="img-fluid" alt=" " />
            </div>{" "}
            <span> CRM</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/ecommerce-app-development">
            <div className="iconimg">
              {" "}
              <img src={images.solutionsimg3} className="img-fluid" alt=" " />
            </div>{" "}
            <span> Ecommerce</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/travel-app-development">
            <div className="iconimg">
              {" "}
              <img src={images.solutionsimg11} className="img-fluid" alt="" />
            </div>{" "}
            <span> Travel</span>
          </Link>
        </li>
        <li
       onClick={() => setNavbartollge(false)}
        >
          <Link to="/whatsapp-bot">
            <div className="iconimg">
              {" "}
              <img src={images.solutionsimg7} className="img-fluid" alt=" " />
            </div>{" "}
            <span> Whatsapp Bot</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/manufacturing-software-development">
            <div className="iconimg">
              {" "}
              <img src={images.solutionsimg8} className="img-fluid" alt="" />
            </div>{" "}
            <span> Manufacturing</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/mobile-app-development-company">
            <div className="iconimg">
              {" "}
              <img src={images.solutionsimg5} className="img-fluid" alt=" " />
            </div>{" "}
            <span> FinTech </span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/healthcare-software-development">
            <div className="iconimg">
              {" "}
              <img src={images.solutionsimg6} className="img-fluid" alt=" " />
            </div>{" "}
            <span> Healthcare</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/real-estate-app-development">
            <div className="iconimg">
              {" "}
              <img
                src={images.solutionsimg9}
                className="img-fluid"
                alt=" "
              />{" "}
            </div>
            <span> Real Estate</span>
          </Link>
        </li>
        {/* <li
          onClick={() => setNavbartollge(false)}
        >
          <Link to="#">
            <div className="iconimg">
              {" "}
              <img
                src={
                  images.solutionsimg10
                }
                className="img-fluid"
              />
            </div>{" "}
            <span>
              {" "}
              Custom Portal Software
            </span>
          </Link>
        </li> */}
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/society-management-app-development">
            <div className="iconimg">
              <img src={images.solutionsimg12} className="img-fluid" alt="" />{" "}
            </div>
            <span> Society Management</span>
          </Link>
        </li>

        <li onClick={() => setNavbartollge(false)}>
          <Link to="/retail-pos-software">
            <div className="iconimg">
              <img src={images.solutionsimg12} className="img-fluid" alt="" />{" "}
            </div>
            <span> RetailPOS</span>
          </Link>
        </li>

        <li onClick={() => setNavbartollge(false)}>
          <Link to="/grocery-app-development">
            <div className="iconimg">
              <img src={images.solutionsimg1} className="img-fluid" alt="" />
            </div>{" "}
            <span>Grocery </span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/job-portal-app-development">
            <div className="iconimg">
              <img src={images.jobportal} className="img-fluid" alt="" />
            </div>{" "}
            <span> Job Portal</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/custom-erp-software-development-company">
            <div className="iconimg">
              <img src={images.solutionsimg11} className="img-fluid" alt="" />
            </div>{" "}
            <span> ERP Systems</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/school-management-software-development-company">
            <div className="iconimg">
              <img src={images.hospitaly} className="img-fluid" alt="" />
            </div>{" "}
            <span> School Management</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/school-management-software-development-company">
            <div className="iconimg">
              <img src={marketplace} className="img-fluid" alt="" />
            </div>
            <span> Marketplace Management</span>
          </Link>
        </li>
      
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/ai-ml-development-services-company">
            <div className="iconimg">
              <img src={MLandAI} className="img-fluid" alt="" />
            </div>
            <span> ML and AI</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/ar-vr-app-development">
            <div className="iconimg">
              <img src={ARandVR} className="img-fluid" alt="" />
            </div>
            <span> AR and VR</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/digital-marketing-agency">
            <div className="iconimg">
              <img src={images.hospitaly} className="img-fluid" alt="" />
            </div>{" "}
            <span> Digital Marketing</span>
          </Link>
        </li>
        <li onClick={() => setNavbartollge(false)}>
          <Link to="/mobile-app-development-company">
            <div className="iconimg">
              <img src={images.hospitaly} className="img-fluid" alt="" />
            </div>{" "}
            <span> App Development</span>
          </Link>
        </li>
        {/* <li
          onClick={() => setNavbartollge(false)}
        >
          <Link to="#">
            <div className="iconimg">
              <img
                src={images.hospitaly}
                className="img-fluid"
                alt="" />
            </div>{" "}
            <span> Web Designing</span>
          </Link>
        </li>
        <li
          onClick={() => setNavbartollge(false)}
        >
          <Link to="#">
            <div className="iconimg">
              <img
                src={images.hospitaly}
                className="img-fluid"
                alt="" />
            </div>{" "}
            <span> Web Development</span>
          </Link>
        </li> */}
      </ul>
    );
  };
  let megamenu1 = () => {
    return (
      <ul>
      

                                                <li
                                                  onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/custom-software-development">
                                                    
                                                    <span>Custom Software Development</span>
                                                  </Link>
                                                </li>
                                            
                                                <li
                                                  onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/ui-and-ux-design">
                                                    
                                                    <span>UI/UX Design</span>
                                                  </Link>
                                                </li>
                                            
                                         
                                            
                                                <li
                                                  onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                    
                                                    <span>PWA</span>
                                                  </Link>
                                                </li>
                                                <li
                                                 onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                   
                                                    <span>
                                                      Mobile first Design
                                                    </span>
                                                  </Link>
                                                </li>
                                             
                                        
                                            
                                                <li
                                                 onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/supply-chain-management">
                                                    
                                                    <span>Supply chain management</span>
                                                  </Link>
                                                </li>
                                                {/* <li
                                                 onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/digital-marketing-agency">
                                                   
                                                    <span>
                                                    Legacy Modernization
                                                    </span>
                                                  </Link>
                                                </li> */}
                                             
                                                <li onClick={() => setNavbartollge(false)}>
          <Link to="/web-development-company-in-india">
            {/* <div className="iconimg">
              <img src={marketplace} className="img-fluid" alt="" />
            </div> */}
            <span> Web App Develpment</span>
          </Link>
        </li>
                                            
                                                <li
                                                 onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/custom-erp-software-development-company">
                                                  
                                                    <span>ERP Software development</span>
                                                  </Link>
                                                </li>
                                                <li
                                                 onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/custom-crm-development-services">
                                                   
                                                    <span>Custom CRM</span>
                                                  </Link>
                                                </li>
                                                <li
                                                 onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/ar-vr-app-development">
                                                   
                                                    <span>AR/VR Development</span>
                                                  </Link>
                                                </li>
                                                {/* <li
                                                  onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                   
                                                    <span>IoT Development</span>
                                                  </Link>
                                                </li> */}
                                                {/* <li
                                                  onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/custom-erp-software-development-company">
                                                   
                                                    <span>Microservices</span>
                                                  </Link>
                                                </li> */}
                                              
                                          
                                                {/* <li
                                                  onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                    
                                                    <span>Cloud Managed Services</span>
                                                  </Link>
                                                </li> */}
                                                {/* <li
                                                  onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                   
                                                    <span>
                                                    Cloud Consulting
                                                    </span>
                                                  </Link>
                                                </li> */}
                                                {/* <li
                                                 onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                   
                                                    <span>
                                                    AWS
                                                    </span>
                                                  </Link>
                                                </li> */}
                                            
{/*                                          
                                                <li

                                                 onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/custom-erp-software-development-company">
                                                  
                                                    <span>Business Intelligence</span>
                                                  </Link>
                                                </li>
                                           
                                            
                                                <li
                                                  onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                    
                                                    <span>SecOps</span>
                                                  </Link>
                                                </li>
                                               
                                             
                                           
                                                <li
                                                  onClick={() => setNavbartollge(false)}
                                                >
                                                  <Link to="/ai-ml-development-services-company">
                                                    
                                                    <span>Generative AI</span>
                                                  </Link>
                                                </li> */}
                                               
                                             
        
    
      </ul>
    );
  };

  return (
    <>
     
      <header className="sticky-top">
      <div className="topbar">
          <div className="container-fluid">
            <ul className="topbar-inner">
              <li className="d-flex gap10">
                <p className="mb-0">
                  <a href="tel:9999634388">
                    {" "}
                    <IoMdCall />
                    +91-9999634388
                  </a>
                  
                  <a href="tel:02030265160" className="pl-10">
                    <IoMdCall />
                    +44-02030265160
                  </a>
                </p>
                <p className="d-lg-block d-none">
                  <a href="mailto:info@ebslon.com">
                    {" "}
                    <FiMail /> info@ebslon.com
                  </a>
                </p>
              </li>
              <li>
                <ul className="social-links">
                  <li className="d-none d-lg-block d-sm-block d-md-block">
                    <a
                      href="https://www.facebook.com/ebsloninfotech/"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaFacebookF />
                    </a>
                  </li>
                  <li className="d-none d-lg-block d-sm-block d-md-block">
                    <a
                      href="https://www.instagram.com/ebsloninfotech/"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaInstagram />
                    </a>
                  </li>
                  <li className="d-none d-lg-block d-sm-block d-md-block">
                    <a
                      href="https://wa.me/message/5X3JK7P5ARXAD1"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaWhatsapp />
                    </a>
                  </li>
                  <li className="d-none d-lg-block d-sm-block d-md-block">
                    <a
                      href="https://twitter.com/ebsloninfotech"
                      className="icon"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaTwitter />
                    </a>
                  </li>
                  <li>
                    <Link
                      onClick={handleShowModal}
                      className="dowload_brochure"
                    >
                      <AiOutlineDownload /> Brochure
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div className="header bootom-bar ">
          <Navbar
            onToggle={() => toggleOffCanvas()}
            expanded={navbartollge}
            expand="lg"
            className="navbar_top bg-white navbartop px-4pc"
          >
            <Container fluid className="p-0">
              <Link to="/" className="main_logo">
                <img src={images.logo} className="main-logo" alt="" />
              </Link>
              <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-lg`}
                aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
                placement="end"
                className="navbar_top_offcanvas"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                    <Link to="/" className="main_logo">
                      <img src={images.logo} className="main-logo" alt="" />
                    </Link>
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="p-0  ">
                  <Nav className="flex-grow-1 justify-content-end">
                    <ul className="navbar-nav mobile_nav flex-grow-1">
                      {/* <li className="nav-item">
                        <NavLink
                          onClick={() => setNavbartollge(false)}
                          className="nav-link"
                          to="/"
                        >
                          Home
                        </NavLink>
                      </li> */}
                      <li className="nav-item">
                        <NavLink
                          onClick={() => setNavbartollge(false)}
                          className="nav-link"
                          to="/"
                        >
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          onClick={() => setNavbartollge(false)}
                          className="nav-link"
                          to="/about-us"
                        >
                          About
                        </NavLink>
                      </li>

                      {windowWidth < 992 ? (
                        <Accordion className="mobile_accroid">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className="headeraccrodion">
                              Services
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="mega-menulist">{megamenu1()}</div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ) : (
                        <li
                          className="nav-item"
                          onMouseEnter={() => setIsShown1(true)}
                          onMouseLeave={() => setIsShown1(false)}
                        >
                          <a href="javascript:void(0);"
                            onClick={() => setNavbartollge(false)}
                            className="nav-link"
                            
                          >
                            Services
                          </a>
                          <div
                            className={`${
                              isShown1 ? "visiblshow" : "visiblhide"
                            } dropdown-wrapper1 `}
                          >
                            <div className="dropdown-inner1">
                              <div className="flex_wrapper1">
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-8">
                                      <div className="left_sidebar">
                                        <div className="row">
                                          <div className="col-lg-4">
                                            <div className="mega_menu_list1">
                                              <h5 className="mega_menu_heading">
                                                IDEATION AND PRODUCT DESIGN
                                              </h5>
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/ui-and-ux-design">
                                                    
                                                    <span>UI/UX Design</span>
                                                  </Link>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="mega_menu_list1">
                                              <h5 className="mega_menu_heading">
                                                MOBILE APP DEV
                                              </h5>
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                    
                                                    <span>PWA</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                   
                                                    <span>
                                                      Mobile first Design
                                                    </span>
                                                  </Link>
                                                </li>
                                              </ul>
                                            </div>
                                            <div className="mega_menu_list1">
                                              <h5 className="mega_menu_heading">
                                              DIGITAL TRANSFORMATION
                                              </h5>
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/supply-chain-management">
                                                    
                                                    <span>Supply chain management</span>
                                                  </Link>
                                                </li>
                                                {/* <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/digital-marketing-agency">
                                                   
                                                    <span>
                                                    Legacy Modernization
                                                    </span>
                                                  </Link>
                                                </li> */}
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="col-lg-4">
                                            <div className="mega_menu_list1">
                                              <h5 className="mega_menu_heading">
                                              SOFTWARE DEVELOPMENT
                                              </h5>
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/custom-software-development">
                                                  
                                                    <span>Custom Software Development</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/web-development-company-in-india">
                                                  
                                                    <span>Web App development</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/custom-erp-software-development-company">
                                                  
                                                    <span>ERP Software development</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/custom-crm-development-services">
                                                   
                                                    <span>Custom CRM</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/ar-vr-app-development">
                                                   
                                                    <span>AR/VR Development</span>
                                                  </Link>
                                                </li>
                                                {/* <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                   
                                                    <span>IoT Development</span>
                                                  </Link>
                                                </li> */}
                                                {/* <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/custom-erp-software-development-company">
                                                   
                                                    <span>Microservices</span>
                                                  </Link>
                                                </li> */}
                                              </ul>
                                            </div>
                                            {/* <div className="mega_menu_list1">
                                              <h5 className="mega_menu_heading">
                                              CLOUD SERVICES
                                              </h5>
                                              <ul>
                                                {/* <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                    
                                                    <span>Cloud Managed Services</span>
                                                  </Link>
                                                </li> 
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                   
                                                    <span>
                                                    Cloud Consulting
                                                    </span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                   
                                                    <span>
                                                    AWS
                                                    </span>
                                                  </Link>
                                                </li> 
                                              </ul>
                                            </div> */}
                                          
                                          </div>
                                          {/* <div className="col-lg-4">
                                            <div className="mega_menu_list1">
                                              <h5 className="mega_menu_heading">
                                              DATA SCIENCE AND ANALYTICS
                                              </h5>
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/custom-erp-software-development-company">
                                                  
                                                    <span>Business Intelligence</span>
                                                  </Link>
                                                </li>
                                           
                                              </ul>
                                            </div>
                                            <div className="mega_menu_list1">
                                              <h5 className="mega_menu_heading">
                                              DEVOPS


                                              </h5>
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                    
                                                    <span>SecOps</span>
                                                  </Link>
                                                </li>
                                               
                                              </ul>
                                            </div>
                                            <div className="mega_menu_list1">
                                              <h5 className="mega_menu_heading">
                                             ARTIFICIAL INTELLIGENCE

                                              </h5>
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown1(false)
                                                  }
                                                >
                                                  <Link to="/ai-ml-development-services-company">
                                                    
                                                    <span>Generative AI</span>
                                                  </Link>
                                                </li>
                                               
                                              </ul>
                                            </div>
                                          
                                          </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="img_heade_menu text-center">
                                        <img src={solutionsimg} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}

                      {windowWidth < 992 ? (
                        <Accordion className="mobile_accroid">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className="headeraccrodion">
                              Solutions
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="mega-menulist">{megamenu()}</div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ) : (
                        <li
                          className="nav-item"
                          onMouseEnter={() => setIsShown(true)}
                          onMouseLeave={() => setIsShown(false)}
                        >
                           <a href="javascript:void(0);"
                            onClick={() => setNavbartollge(false)}
                            className="nav-link"
                           
                          >
                            Solutions
                          </a>
                          <div
                            className={`${
                              isShown ? "visiblshow" : "visiblhide"
                            } dropdown-wrapper1 `}
                          >
                            <div className="dropdown-inner1">
                              <div className="flex_wrapper1">
                                <div className="container-fluid">
                                  <div className="row">
                                    <div className="col-8">
                                      <div className="left_sidebar">
                                        <h3>On Demand Solutions</h3>
                                        <div className="row">
                                          <div className="col-lg-4">
                                            <div className="mega_menu_list">
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/educational-app-development">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimg12
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> ED Tech</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/custom-crm-development-services">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimg1
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> CRM</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/ecommerce-app-development">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimg3
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> Ecommerce</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/travel-app-development">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimg11
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> Travel</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/whatsapp-bot">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimgwhatsappbot
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> Whatsapp Bot</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/digital-marketing-agency">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimgdigitalmkt
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span>
                                                      {" "}
                                                      Digital Marketing
                                                    </span>
                                                  </Link>
                                                </li>

                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/ar-vr-app-development">
                                                    <div className="iconimg">
                                                      <img
                                                        src={ARandVR}
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> AR and VR</span>
                                                  </Link>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="col-lg-4">
                                            <div className="mega_menu_list">
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/manufacturing-software-development">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimg8
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> Manufacturing</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/fintech-app-development-company">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimg5
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> FinTech </span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/healthcare-software-development">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimg6
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> Healthcare</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/real-estate-app-development">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimg9
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />{" "}
                                                    </div>
                                                    <span> Real Estate</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/society-management-app-development">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimgsociety
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span>
                                                      {" "}
                                                      Society Management
                                                    </span>
                                                  </Link>
                                                </li>

                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/mobile-app-development-company">
                                                    <div className="iconimg">
                                                      {" "}
                                                      <img
                                                        src={
                                                          images.solutionsimgappdevelopment
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span>
                                                      {" "}
                                                      App Development
                                                    </span>
                                                  </Link>
                                                </li>

                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/ai-ml-development-services-company">
                                                    <div className="iconimg">
                                                      <img
                                                        src={MLandAI}
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> ML and AI</span>
                                                  </Link>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <div className="col-lg-4">
                                            <div className="mega_menu_list">
                                              <ul>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/retail-pos-software">
                                                    <div className="iconimg">
                                                      <img
                                                        src={
                                                          images.solutionsimg12
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />{" "}
                                                    </div>
                                                    <span>Retail POS</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/grocery-app-development">
                                                    <div className="iconimg">
                                                      <img
                                                        src={
                                                          images.solutionsimg1
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span>Grocery </span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/job-portal-app-development">
                                                    <div className="iconimg">
                                                      <img
                                                        src={images.jobportal}
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> Job Portal</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/custom-erp-software-development-company">
                                                    <div className="iconimg">
                                                      <img
                                                        src={
                                                          images.solutionsimg11
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span> ERP Systems</span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/school-management-software-development-company">
                                                    <div className="iconimg">
                                                      <img
                                                        src={
                                                          images.solutionsimgschoolmanagement
                                                        }
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span>
                                                      {" "}
                                                      School Management
                                                    </span>
                                                  </Link>
                                                </li>
                                                <li
                                                  onClick={() =>
                                                    setIsShown(false)
                                                  }
                                                >
                                                  <Link to="/marketplace-management">
                                                    <div className="iconimg">
                                                      <img
                                                        src={marketplace}
                                                        className="img-fluid"
                                                        alt=""
                                                      />
                                                    </div>{" "}
                                                    <span>
                                                      {" "}
                                                      MarketPlace Management
                                                    </span>
                                                  </Link>
                                                </li>

                                                {/* <li
                                                  onClick={() => setIsShown(false)}
                                                >
                                                  <Link to="/web-development-company">
                                                    <div className="iconimg">
                                                      <img
                                                        src={images.hospitaly}
                                                        className="img-fluid"
                                                        alt="" />
                                                    </div>{" "}
                                                    <span> Web Development</span>
                                                  </Link>
                                                </li> */}
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="img_heade_menu text-center">
                                        <img src={menuimg} alt="" />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}

                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          onClick={() => setNavbartollge(false)}
                          to="/technologies"
                        >
                          Technologies
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          onClick={() => setNavbartollge(false)}
                          className="nav-link"
                          to="/case-studies"
                        >
                          Case Studies
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          onClick={() => setNavbartollge(false)}
                          className="nav-link"
                          to="/portfolio"
                        >
                          Portfolio
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink
                          onClick={() => setNavbartollge(false)}
                          className="nav-link"
                          to="/contact-us"
                        >
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        </div>
      </header>

      <Modal
        className="brochuremodal"
        centered
        size="lg"
        show={showModal}
        onHide={handleCloseModal}
      >
        {/* <Modal.Header className="closebtnme"></Modal.Header> */}
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6  d-none d-lg-block d-sm-block d-md-block col-12 p-0 ">
              <div className="borchureimgheader">
              <img
                  src={brochure}
                  alt="brochure"
                
                />
              </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-12  brochurepadding">
                <button onClick={handleCloseModal} className="closebuttn">
                  <img src={crossicn} alt="crossicn" className="img-fluid" />
                </button>
                <div className="text-center">
                  <h3 className="main-heading"> Brochure </h3>
                </div>
                <div className="row mt-4">
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <input
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        type="text"
                        className="form-control"
                        placeholder="Enter Full Name"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        type="email"
                        className="form-control"
                        placeholder="Enter Your Email Id"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <input
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        type="tel"
                        className="form-control"
                        maxLength="10"
                        placeholder="Enter Your Number"
                      />
                    </div>
                  </div>

                  <div className="col-lg-12 text-center mt-3">
                    <button
                      className="submit_buttn"
                      onClick={() => HandleSubmitBrochureEnquiry()}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
